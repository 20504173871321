/** External links disclaimer message */
jQuery(document).ready(function ($) {
  $('a[href^="http://"], a[href^="https://"]')
    .not('[href*="' + window.location.host + '"]')
    .each(function () {
      var externalLink = $(this).attr('href');

      // Exclude links to emails, phone numbers, fax numbers, and specified downloadable files
      if (
        !externalLink.match(
          /(mailto:|tel:|fax:|.zip$|.xls$|.xlsx$|.pdf$|.jpg$|.png$|.ppt$|.pptx$|.doc$|.docx$)/i
        )
      ) {
        // Prevent the default action of the anchor
        $(this).on('click', function (e) {
          e.preventDefault();
        });

        $(this).magnificPopup({
          closeBtnInside: false,
          type: 'inline',
          items: {
            src:
              '<div class="external-links dbp-popup white-popup">' +
              '<h1>Disclaimer for External Links</h1>' +
              `<p>You are now leaving the Development Bank of the Philippines' website. Kindly be informed that we are only responsible for the content we post (see <a href="https://www.dbp.ph/privacy-statement/?utm_source=dbp_website&utm_medium=popup_link&utm_campaign=external_link_disclaimer">DBP Privacy Statement</a>). The website you are trying to reach is responsible for their content and security level. Please refer to their privacy statement for more details.</p><p>Thank you.</p>` +
              `<div class="mpf-controls">` +
              '<a href="#" role="button" class="custom-close primary-action">Cancel</a> ' + // Custom Close popup link
              '<a href="' +
              externalLink +
              '" role="button" target="_blank" class="proceed-external secondary-action">OK</a>' +
              `</div>` + // Ok link to proceed
              `</div>`,
          },
          callbacks: {
            open: function () {
              // When the OK button is clicked, close the popup
              $('.proceed-external').on('click', function () {
                $.magnificPopup.close();
              });

              // When the custom close (Cancel) button is clicked, close the popup
              $('.custom-close').on('click', function (e) {
                e.preventDefault();
                $.magnificPopup.close();
              });
            },
          },
        });
      }
    });
});
